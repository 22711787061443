.section {
    overflow: auto;
    /* width: 100%; */
    height: 100%;
    display: flex;
    
    transition: width 0s ease 0s, left 0s ease 0s, right 0s ease 0s, all 300ms ease 0s;
    flex-direction: column;
}

.section.collapsed {
    width: 100%;
    transition: width 0s ease 0s, left 0s ease 0s, right 0s ease 0s, all 300ms ease 0s;
}

.section-container {
    margin: 10px 50px 50px 50px;
    min-height: 500px;
    border-radius: 10px;
}