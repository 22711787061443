.nav {
  transition: all 100ms ease-in-out;
  height: 70px;
}

.nav-toggle {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 30px;
  width: 30px;
}

.nav-toggle .bar {
  height: 3px;
  width: 100%;
  background-color: #03091d;
  transition: all 100ms ease-in-out;
}

.bar:nth-of-type(1) {
  margin-top: 5px;
}

.bar:nth-of-type(3) {
  margin-bottom: 5px;
}


.x:nth-of-type(1) {
  transition: all 100ms ease-in-out;
  transform: rotate(45deg);
  transform-origin: top left;
  width: 38px;
  margin: 0px;
}

.x:nth-of-type(2) {
  transition: all 100ms ease-in-out;
  transform-origin: center;
  width: 0;
}

.x:nth-of-type(3) {
  transition: all 100ms ease-in-out;
  transform: rotate(-45deg);
  transform-origin: bottom left;
  width: 38px;
  margin: 0px;
}


.nav-toggle:hover {
  cursor: pointer;
}

.x:nth-of-type(1) {
  transition: all 100ms ease-in-out;
  transform: rotate(45deg);
  transform-origin: top left;
  width: 39px;
}

.x:nth-of-type(2) {
  transition: all 100ms ease-in-out;
  transform-origin: center;
  width: 0;
}

.x:nth-of-type(3) {
  transition: all 100ms ease-in-out;
  transform: rotate(-45deg);
  transform-origin: bottom left;
  width: 39px;
}
