@font-face {
  font-family: Sofia Pro;
  src: url("./fonts/SofiaProRegular.eot?#iefix") format("embedded-opentype"),
    url("./fonts/SofiaProRegular.otf") format("otf"),
    url("./fonts/SofiaProRegular.ttf") format("truetype"),
    url("./fonts/SofiaProRegular.woff2") format("woff2"),
    url("./fonts/SofiaProRegular.woff") format("woff");
}

@font-face {
  font-family: Albra;
  src: url("./fonts/Albra-Regular.eot?#iefix") format("embedded-opentype"),
    url("./fonts/Albra-Regular.ttf") format("truetype"),
    url("./fonts/Albra-Regular.woff2") format("woff2"),
    url("./fonts/Albra-Regular.woff") format("woff");
}

@font-face {
  font-family: Sofia Pro Semi;
  src: url("./fonts/SofiaProSemiBold.otf?#iefix") format("embedded-opentype"),
    url("./fonts/SofiaProSemiBold.otf") format("otf"),
    url("./fonts/SofiaProSemiBold.ttf") format("truetype"),
    url("./fonts/SofiaProSemiBold.woff2") format("woff2"),
    url("./fonts/SofiaProSemiBold.woff") format("woff");
}
