.html-markup {
  margin-top: 20px;
  font-family: Sofia Pro;
  color: rgba(0, 0, 0, 0.7);
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: revert-layer;
    font-weight: revert-layer;
  }

  h2 {
    font-size: 24px;
  }

  ol,
  ul,
  menu {
    padding: 0px 0px 0px 40px;
    font-size: 18px;
  }

  ol {
    list-style: decimal;
  }

  ul {
    list-style: disc;
  }

  p {
    margin: 16px 0px;
    font-size: 18px;
  }

  img {
    width: 100%;
  }

  em {
    font-style: normal;
  }

  a {
    color: #0000ee;
    text-decoration: underline;
  }
}

@media not all and (min-width: 640px) {
  .html-markup {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
    }

    ol,
    ul,
    menu {
      padding: 0px 0px 0px 20px;
    }

    ol {
    }

    ul {
    }

    p {
      margin: 16px 0px;
    }

    img {
    }

    a {
      color: #0000ee;
      text-decoration: underline;
    }
  }
}

.article-detail {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.article-detail-image-container {
  height: 376px;
  overflow: hidden;
  margin: 20px;
  border-radius: 10px;
}

.article-detail-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.article-detail-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 0px;
}

.article-detail-title {
  font-size: 14px;
  font-family: Sofia Pro;
  font-weight: 400;
  word-wrap: break-word;
  text-transform: uppercase;
}

.article-detail-info {
  color: #2e2c34;
  font-weight: 400;
  word-wrap: break-word;
}

.article-detail-time {
  font-size: 14px;
  font-family: Sofia Pro;
  font-weight: 400;
  word-wrap: break-word;
}

.video-container {
  &:focus-visible {
    outline: none;
  }
}