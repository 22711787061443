.card-image-container {
  overflow: hidden;
  border-radius: 10px;
}

.card-image {
  object-position: top;
}

.card-content {
  align-items: flex-start;
}

.card-title {
  font-family: Sofia Pro;
  word-wrap: break-word;
}

.card-info {
  color: #23262f;
  font-family: Sofia Pro;
  word-wrap: break-word;
}

.card-time {
  color: #706f6f;
  font-family: Sofia Pro;
  word-wrap: break-word;
}

.initial-card {
}

@media not all and (min-width: 640px) {
  .initial-card {
  }
}
