.sideMenu-mobile {
  .sidebar {
    width: 100%;
    flex-shrink: 0;
    height: calc(100% - 120px);
    overflow: auto;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .sidebar-title {
    cursor: pointer;
    color: #1c355e;
    display: flex;
    position: relative;
    width: 100%;
  }

  .sidebar-content {
    max-height: 0;
    transition: max-height 0.15s ease-out;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    align-items: flex-start;
  }

  .sidebar-item {
    width: 100%;
  }

  .sidebar-item.open > .sidebar-content {
    max-height: 500px;
    transition: max-height 0.25s ease-in;
  }

  .sidebar-item-text {
    color: #79767b;
    font-size: 16px;
    display: flex;
    width: 100%;
    transition: 0.3s ease;
  }

  .sidebar-item-text:active {
    color: #1c355e !important;
    transition: 0.3s ease;
  }

  .sidebar-item-text:active > .sidebar-content .sidebar-item-label {
    background-color: rgba(28, 53, 94, 0.06);
    transition: 0.3s ease;
  }

  .sidebar-item-text,
  .sidebar-content .sidebar-item-text.active {
    color: #1c355e;
  }

  .sidebar-content .sidebar-item-text {
    color: #79767b;
  }

  .sidebar-content .sidebar-item-text {
    border-left: 1.5px #f3eef6 solid;
    padding-left: 20px;
    position: relative;
  }

  .sidebar-content .sidebar-item-text.active::before {
    content: "";
    width: 3px;
    height: 24px;
    background-color: #1c355e;
    position: absolute;
    left: -2px;
    top: 13px;
  }

  .sidebar-item-arrow {
    position: absolute;
    right: 0px;
    top: 22px;
    transition: 0.2s ease;
  }

  .sidebar-item.open .sidebar-item-arrow {
    transform: rotateZ(180deg);
    transition: 0.2s ease;
  }

  .sidebar-item-icon {
    padding: 0px 12px;
    display: flex;
  }

  .sidebar-item-arrow {
    padding: 0px 12px;
    display: flex;
  }

  .sidebar-item-label {
    padding: 12px 0px;
    border-radius: 12px;
    width: 100%;
    align-items: flex-start;
    display: flex;
    text-align: left;
  }

  .active > .sidebar-item-label {
    background-color: rgba(28, 53, 94, 0.06);
    padding-left: 12px;
  }
}

.sideMenu-mobile.collapsed {
  /* width: 80px; */
  transition: width 0s ease 0s, left 0s ease 0s, right 0s ease 0s,
    all 300ms ease 0s;
}

.sideMenu-mobile-toggler {
  width: 32px;
  height: 32px;
  border-radius: 25px;
  background-color: #fff;
  cursor: pointer;
  position: absolute;
  right: 0;
  transform: translateX(50%);
  display: flex;
  bottom: 200px;
}
